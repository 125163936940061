:root {
	--gold-color: #c0a868;
	--sgblu-color: #20275b;
}
html,
body {
	background-color: var(--sgblu-color);
}

*::selection {
	color: #c0a868;
	background-color: #0000003e;
}

*::-moz-selection {
	color: #c0a868;
	background-color: #0000003e;
}
*::-webkit-selection {
	color: #c0a868;
	background-color: #0000003e;
}

body {
	font-family: "Roboto", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "Poppins", serif;
	color: var(--gold-color);
}
#navbar,
.footer {
	display: none;
}
footer {
	border-radius: 25px;
	transition: all 0.3s ease-in-out;
}
.link-body {
	background-color: #061754 !important;
}
.link-footer {
	padding: 30px 0;
	margin-top: 30px;
	padding-bottom: 20px;
	color: #ffffff;
	font-size: 12px;
	font-weight: 500;
}
.bg-action {
	background-color: #20275b;
	border-radius: 25px;
	color: var(--gold-color);
	transition: all 0.3s ease-in-out;
}
.bg-action:hover {
	background-color: #2f3980;
	filter: drop-shadow(0 0 5px #fff);
	color: white;
}
.vertical-center {
	vertical-align: middle !important;
}
.link-card a,
.link-card .link-btn-rounded {
	border-radius: 40px;
	padding: 16px;
	min-height: 360px;
	max-height: 360px;
}

.product-card-a {
	border-radius: 40px;
	padding: 16px;
	min-height: 360px;
	max-height: 360px;
	height: 100%;
	min-height: inherit;
}

.link-card-single div:first-child,
.link-card-single div:first-child a,
.product-card-a {
	height: 100%;
	min-height: inherit;
}

.link-card-single div:first-child a,
.product-card-a {
	max-height: fit-content !important;
}

.product-link-title {
	font-size: 18px;
}

.product-link-description {
	font-size: 14px;
}

.slick-track {
	margin-left: 0 !important;
}

.font-weight-medium2 {
	font-weight: 600;
}

.profile-img-div {
	justify-content: center;
}

.profile-info-div h2,
.profile-info-div p,
.profile-info-div small {
	text-align: center;
}

.slick-list {
	padding: 0 21% 0 5% !important;
}

@media (max-width: 767px) {
	.user-avatar {
		width: 100px;
		height: 100px;
	}

	.profile-main {
		padding: 25px 0;
	}
}

@media (max-width: 550px) {
	footer {
		height: auto;
	}
	.checkout-modal-dialog {
		width: 96% !important;
	}
}

@media (max-width: 500px) {
	.purchase-btn,
	.price-btn {
		min-width: 45% !important;
		font-size: 14px !important;
	}
}

.slick-track {
	display: flex !important;
}

.slick-slide {
	height: inherit !important;
}

.links-slider {
	flex-basis: 100%;
	padding-left: 10px;
	padding-right: 10px;
	padding-bottom: 10px;
	padding-top: 10px;
}

.links-slider-main {
	margin-bottom: 10px;
}

._embed_video_wrapper {
	border: none !important;
}

.links-slider-main::-webkit-scrollbar {
	height: 0.5em;
}

.links-slider-main::-webkit-scrollbar-thumb {
	background-color: #d3d3d3;
	border-radius: 50px;
}

@media (min-width: 767px) {
	.links-slider {
		flex-wrap: wrap;
		display: flex;
		align-items: center;
		margin: 0;
	}
}

@media (max-width: 767px) {
	.link-content {
		padding: 0rem 1rem;
	}

	.links-slider {
		display: flex;
	}
}

.post-card h4,
.post-card p {
	color: #ffffff !important;
}

.single-link-block-arrow svg rect {
	fill: #ffffff !important;
}

.folder-title {
	color: #ffffff !important;
}

.product-title,
.product-short-description,
.product-description {
	color: #ffffff !important;
}
.product-title {
	letter-spacing: -1px;
	font-weight: 600;
}
.folder-title {
	font-size: 22px;
	text-align: center;
	font-weight: 500 !important;
}

.btn-primary:focus,
.btn-primary.focus {
	box-shadow: 0px 0px 15px #00008024 !important;
}

.div-block-342,
.product-card-price-badge {
	color: #fff;
}

.profile_title {
	overflow-wrap: anywhere;
}

.single-link-block-img {
	background-color: #0f2465 !important;
}
.widget-block {
	background-color: #0f2465 !important;
}
.accordion-body {
	background-color: #0f2465 !important;
	color: #ffffff !important;
	border-color: #0f2465 !important;
}

.tel {
	color: white;
	text-decoration: none;
}

.tel-name {
	color: #fff;
}

.tel:hover {
	color: var(--gold-color);
	text-decoration: underline;
}
.text-deco-none {
	text-decoration: none;
}

.text-deco-none:hover {
	text-decoration: none;
}

.tel-name:hover {
	color: var(--gold-color);
	text-decoration: underline;
}

@media (max-width: 950px) {
	.tel {
		color: white;
		text-decoration: underline;
	}

	.tel:hover {
		color: white;
		text-decoration: none;
	}
}

.content-text a {
	color: #ffffff;
	text-decoration: underline;
	font-weight: 600;
}

.product-content-divider {
	background: #ffffff !important;
	opacity: 0.2;
}
.product-content-image {
	border: 2px solid #ffffff;
}

.mt-0 {
	margin-top: 0 !important;
}

/* Per dispositivi fino a 468px */
@media (max-width: 468px) {
	.main-bio-section::before {
		-webkit-background-size: 300%;
		background-size: 300%;
	}
}

/* Da 469px fino a 749px */
@media (min-width: 469px) and (max-width: 749px) {
	.main-bio-section::before {
		-webkit-background-size: 280%;
		background-size: 280%;
	}
}

/* Da 750px fino a 950px */
@media (min-width: 750px) and (max-width: 950px) {
	.main-bio-section::before {
		-webkit-background-size: 200%;
		background-size: 200%;
	}
}

/* Da 951px fino a 1200px */
@media (min-width: 951px) and (max-width: 1200px) {
	.main-bio-section::before {
		-webkit-background-size: 200%;
		background-size: 200%;
	}
}
@media (min-width: 1201px) and (max-width: 1400px) {
	.main-bio-section::before {
		-webkit-background-size: 150%;
		background-size: 150%;
	}
}

.main-bio-section > * {
	position: relative;
	z-index: 1;
}

.user-name {
	font-size: 30px;
	font-style: normal;
	font-weight: 500;
	line-height: 1.1em;
	margin-top: 20px;
	color: #fff !important;
}
.user-descrip {
	font-style: normal;
	margin-top: 10px;
	font-size: 16px;
	font-weight: 500;
	line-height: 1.3em;
	color: #fff !important;
}
.social-links-user {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	gap: 10px;
	margin-block: 10px;
}

.icon {
	transition: 0.5s ease-out !important;
	width: 40px;
	height: 40px;
}
.social-links-u svg path {
	fill: #1e242a !important;
}
.social-links-u:hover {
	opacity: 0.5;
}
.promo-u {
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	font-weight: 500;
	gap: 10px;
	font-size: 14px;
	line-height: 14px;
	background-color: #000;
	border-radius: 100px;
	padding: 13px 20px 14px;
	margin-bottom: 50px;
}
.switch-button-area {
	max-width: 800px;
	width: 100%;
	padding: 10px 0;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
.switch-button.active {
	background-color: #0f2465 !important;
	border: none;
}
.switch-button {
	text-align: center;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	padding: 7px 18px;
	border-radius: 36px;
	transition: 0.3s;
	cursor: pointer;
	color: #ffffff;
}
.switch-button:not(.active):hover {
	opacity: 0.5;
}
.cont-circle-area {
	display: flex;
	align-items: center;
	gap: 8px;
}
.expadd-20 {
	padding-left: 20px;
}
.cont-circle-area > span {
	font-size: 20px;
	font-weight: 500;
	line-height: 20px;
	text-decoration: none;
	color: #54c3f2 !important;
	width: 80%;
	text-wrap: wrap;
	display: inline-block;
	word-break: break-word;
	line-height: 1.5;
}
.con-circle {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 100%;
	z-index: 99;
	width: 40px;
	height: 40px;
	position: relative;
	background-color: #54c3f2;
}
.next-arrow svg path,
.con-circle svg path {
	stroke: #1e242a;
}
.play-icon svg path {
	stroke: none;
	fill: #1e242a !important;
}
.contact-area {
	max-width: 800px;
	width: 100%;
}
.social-area {
	max-width: 800px;
	width: 100%;
}
.links-area {
	position: relative;
	max-width: 800px;
	width: 100%;
}
.p_l-24 {
	padding-left: 47px;
}
.position-relative {
	position: relative;
	width: 100%;
}
.link-slide {
	overflow-y: hidden;
	overflow-x: auto;
}
.link-slide::-webkit-scrollbar {
	display: none;
}
.link-box {
	width: fit-content;
	display: flex;
	gap: 15px;
	margin-top: 20px;
	padding: 0 20px 0 0;
}
.link-cards {
	width: 250px !important;
	border-radius: 24px;
	overflow: hidden;
	text-decoration: none;
	transition: 0.3s ease-in-out !important;
	height: 320px;
	display: flex;
	flex-direction: column;
}
.link-cards:hover {
	transform: translateY(-1%);
}
.link-cards a {
	display: flex;
	flex-direction: column;
	height: 100%;
}
.card-banner-image {
	max-height: 150px;
	padding: 10px;
}
.card-banner-image > img {
	width: 100%;
	height: 139px;
	object-fit: cover;
	border-radius: 10px;
	object-position: center;
}
.card-cont {
	height: 100%;
	position: relative;
	display: flex;
	align-items: flex-end;
	width: 100%;
	padding: 20px;
	padding-top: 10px;
}

.cont-box-card > h2 {
	font-size: 20px !important;
	font-weight: 500 !important;
	line-height: 1.1;
	text-decoration: none;
	margin-bottom: 0px;
}

.cont-box-card > p {
	color: #e4f0c2;
	margin-top: 12px;
	margin-bottom: 0px;
	font-size: 16px;
	font-weight: 500;
	line-height: 1.2em;
}
.cont-black > h2 {
	color: #000;
}
.cont-black > p {
	color: #000;
}
.visit-link {
	margin-top: 10px;
	width: fit-content;
	padding: 6px 15px;
	background-color: #fff;
	border-radius: 56px;
	text-decoration: none;
	font-size: 16px;
	color: #000;
	mix-blend-mode: normal;
	font-size: 14px;
	font-weight: 500;
	border: none;
}
.visit-link2 {
	width: fit-content;
	height: 30px;
	background-color: #2c2c2c;
	border-radius: 30px;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-top: 0;
	margin-right: 0;
	padding-left: 18px;
	padding-right: 18px;
	text-decoration: none;
	display: flex;
	color: white;
	font-weight: 500;
	font-size: 14px;
}
.text-yellow {
	color: #ffc508;
}
.next-arrow {
	position: absolute;
	top: 50%;
	z-index: 99;
	width: 40px;
	height: 40px;
	background-color: #585858;
	border-radius: 100%;
	justify-content: center;
	align-items: center;
	margin-right: 7px;
	opacity: 1;
	display: flex !important;
	transition: 0.3s;
	background-color: #54c3f2;
}
.left-arrow {
	left: 0px;
	cursor: pointer;
}
.right-arrow {
	right: 0px;
	cursor: pointer;
}
.fade-left {
	background-image: linear-gradient(-90deg, rgba(52, 54, 47, 0), #061754);
	width: 100px;
	height: 360px;
	position: absolute;
	left: 0;
	z-index: 3;
	bottom: 0;
}
.instagram-right-fade {
	height: 320px !important;
}
.fade-right {
	background-image: linear-gradient(90deg, rgba(52, 54, 47, 0), #061754);
	width: 100px;
	height: 360px;
	position: absolute;
	right: 0;
	z-index: 3;
	bottom: 0;
}
.banner-box {
	width: 100%;
	border-radius: 24px;
	background: #0f2465;
	padding: 24px 22px;
	margin-top: 20px;
}
.inp-area {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 12px;
	margin-top: 21px;
}
.inp-area > input {
	width: 100%;
	border: none;
	outline: none;
	padding: 15px 18px;
	border-radius: 48px;
	background: #33362f;
	color: #fff;
	font-size: 15px;
	font-style: normal;
	font-weight: 500;
	line-height: 106.1%;
}
.inp-area > input::placeholder {
	/* color: white; */
	font-weight: 500;
}
.message-box {
	margin-top: 15px;
}
.message-box > textarea {
	width: 100%;
	border-radius: 21px;
	/* background: #33362F; */
	border: none;
	outline: none;
	padding: 10px;
	background-color: #061754 !important;
	color: #ffffff !important;
}
.message-box > textarea::placeholder {
	color: #ffffff !important;
}
.sub-btn {
	border: none;
	outline: none;
	border-radius: 56px;
	background: #fff;
	color: #292b26;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	display: inline-flex;
	height: 30px;
	padding: 6px 20px;
	justify-content: center;
	align-items: center;
	gap: 5px;
	margin-top: 21px;
}
.para-cont {
	color: #fff;
	font-size: 15px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}
.inp-area2 {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 5px;
	margin-top: 21px;
}
.inp-area2 > input {
	width: 100%;
	border: none;
	outline: none;
	padding: 0px 18px;
	border-radius: 48px;
	background-color: #061754 !important;
	color: #ffffff;
	font-size: 15px;
	font-style: normal;
	font-weight: 500;
	line-height: 106.1%;
	height: 50px;
}
.inp-area2 input::placeholder,
.message-box > textarea::placeholder,
.inp-area2 input {
	color: #ffffff !important;
}

.sub-btn2 {
	border: none;
	/* color: #fff; */
	font-size: 16px;
	font-weight: 500;
	line-height: 16px;
	width: fit-content;
	height: 45px;
	border-radius: 45px !important;
	justify-content: center !important;
	align-items: center !important;
	padding-left: 20px !important;
	padding-right: 20px !important;
	text-decoration: none;
	display: flex;
	background-color: #061754 !important;
	color: #ffffff !important;
	border: none;
}
.sub-btn2 img {
	filter: invert(0%);
}
.mt-20 {
	margin-top: 20px;
}
.banner-box2 {
	width: 100%;
	border-radius: 24px;
	background: #292b26;
	padding: 12px;
	margin-top: 36px;
	display: flex;
	align-items: center;
	gap: 28px;
}
.best-link {
	width: 100%;
	border-radius: 25px;
	gap: 15px;
	align-items: center;
	padding: 12px;
	text-decoration: none;
	transition: all 0.2s;
	overflow: hidden;
	margin-top: 20px;
	color: #ffffff;
	transition: all 0.3s ease-in-out;
}
.doc-link {
	border-radius: 25px;
	transition: all 0.3s ease-in-out;
}
.best-link:hover,
.doc-link:hover {
	transform: scale(1.01);
}
a:hover {
	text-decoration: none;
	color: #ffffff;
}
.visit-link {
	color: #000000;
}
.banner-img {
	width: 130px;
	min-width: 130px;
	border-radius: 15px;
	justify-content: center;
	align-items: center;
	display: flex;
	position: relative;
	overflow: hidden;
}

.banner-box2_right > h2 {
	color: #fff;
	font-size: 19px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	text-align: left;
}
.banner-box2_right > p {
	color: #fff;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	margin-top: 7px;
	text-align: left;
	width: 305px;
}
.banner-box2_right > a {
	border-radius: 56px;
	background: #fff;
	display: inline-flex;
	padding: 6px 20px;
	justify-content: center;
	align-items: center;
	gap: 5px;
	flex-shrink: 0;
	text-decoration: none;
	color: #000000;
	margin-top: 15px;
}
.playbutton {
	margin-left: 24px;
}
.tiktok_video {
	width: 303px;
	height: 539px;
	border-radius: 17px;
	overflow: hidden;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
}
.tiktok_video > img {
	height: 100%;
	pointer-events: none;
}
.extra-height {
	height: 545px;
}
.video-play {
	position: absolute;
}
.nopadding {
	padding: 0 0 !important;
	padding-bottom: 15px !important;
	overflow: hidden !important;
}
.padding-24 {
	padding: 20px;
}
.textbox {
	min-height: 59px;
	border-radius: 30px;
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 8px 20px;
	color: #ffffff;
}
.link-description {
	margin-top: 12px;
	margin-bottom: 12px;
	font-size: 16px;
	font-weight: 500;
	line-height: 1.2em;
}
.youtube-videos {
	width: 100%;
	height: auto;
	max-width: 800px;
	background-color: #0d0101;
	border-radius: 30px;
	padding: 10px;
	position: relative;
	overflow: hidden;
	margin-top: 20px;
}
.youtube-videos > img {
	width: 100%;
	border-radius: 20px;
}
.youtube-videos > span {
	color: #e4f0c2;
	font-size: 20px;
	font-weight: 500;
	line-height: 20px;
	text-decoration: none;
	position: absolute;
	bottom: 0;
	left: 0;
}
.div-block-535 {
	width: 100%;
	height: auto;
	background-color: rgba(41, 43, 38, 0);
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-start;
	padding: 20px;
	display: flex;
	position: absolute;
	top: auto;
	bottom: 0%;
	left: 0%;
	right: 0%;
}
.zaapheading {
	font-size: 20px;
	font-weight: 500;
	line-height: 20px;
	text-decoration: none;
}
.spaceer-10 {
	width: 100%;
	height: 15px;
}
.link-button {
	width: auto;
	height: 30px;
	background-color: #2c2c2c;
	border-radius: 30px;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-top: 0;
	margin-right: 0;
	padding-left: 18px;
	padding-right: 18px;
	text-decoration: none;
	display: flex;
	color: white;
}
.color-purple {
	background-color: #7b30e0;
}

@media (max-width: 1200px) {
	.main-bio-section > div {
		flex-direction: column;
		align-items: center;
	}
	.content-area {
		align-items: center;
		padding-left: 0px !important;
	}
	.user-area {
		padding-left: 0px !important;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 0;
		justify-content: flex-start;
		height: 100%;
		position: static;
		max-width: 735px;
	}
	.user-detail-area {
		max-width: 800px;
		width: 100%;
	}
	.content-area {
		width: 100%;
		padding-top: 0px !important;
	}
	.fade-right,
	.fade-left {
		width: 30px;
	}
}
@media (max-width: 767px) {
	.next-arrow {
		display: none !important;
	}
	.link-slide {
		margin-right: -20px;
		margin-left: -20px;
		padding: 0 20px;
	}
	.fade-right,
	.fade-left {
		display: none !important;
	}
}

@media (max-width: 660px) {
	.banner-box2 {
		flex-direction: column;
		align-items: flex-start;
	}
	.banner-img {
		width: 100%;
		height: auto;
	}

	.inp-area2 {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 12px;
		margin-top: 21px;
		flex-wrap: wrap;
	}
	.inp-area2 > input {
		flex-basis: 48%;
	}
	.sub-btn {
		width: 100%;
		background: #33362f;
		color: #fff;
		height: 45px;
	}
	.sub-btn > svg > path {
		fill: #fff;
	}
	.sub-btn2 {
		width: 100%;
	}
}
@media (max-width: 530px) {
	.switch-button-area {
		justify-content: center;
	}
	.user_profile_pic {
		width: 114px;
		height: 114px;
	}
	.user-name {
		font-size: 25px;
		text-align: center;
	}
	.user-descrip {
		font-size: 14px;
		/* max-width: 300px; */
		width: 100%;
		text-align: center;
	}
	.cont-box-card > h2 {
		font-size: 18px;
	}
	.banner-box {
		padding: 12px;
	}
	.best-link {
		flex-direction: column;
		padding: 0;
		gap: 0;
	}
	.banner-img {
		padding: 10px;
	}
	.banner-img > img {
		border-radius: 10px;
	}
	.visit-single-link {
		text-align: left;
		padding-bottom: 14px;
	}

	.singlelinkcontent .zaapheading,
	.singlelinkcontent .link-description {
		text-align: center;
	}
	.banner-img {
		border-radius: 0;
	}
	.user-area {
		padding: 20px;
	}
	.user-detail-area {
		display: flex !important;
		justify-content: center !important;
		flex-direction: column !important;
		align-items: center !important;
	}
	.social-links-user {
		justify-content: center !important;
	}
	.link-description {
		margin-bottom: 0;
	}
	.singlelinkcontent {
		padding: 15px;
		/* background: linear-gradient(0deg, rgb(255 98 62)60%, transparent); */
		padding-top: 6rem;
		margin-top: -5rem;
		z-index: 1;
	}
}
@media (max-width: 500px) {
	.forwebpromo {
		display: none;
	}
	.footer-area,
	.footer-area .forwebpromo {
		display: flex !important;
	}
}
@media (max-width: 420px) {
	.inp-area2 > input {
		flex-basis: 100%;
	}
	.inp-area {
		flex-wrap: wrap;
	}
	.fade-right,
	.fade-left {
		width: 20px;
	}
}

.best-single-link {
	/* display: flex; */
	justify-content: space-between;
	align-items: center;
	box-shadow: none;
}
.best-link-div {
	flex-direction: column;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 15px;
}
.visit-single-link {
	padding-left: 14px;
}
.view-insta-btn {
	background: #ffffff;
	color: #061754;
	border-radius: 50px;
	padding: 0px 18px;
	text-wrap: nowrap;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	height: 30px;
	display: flex;
	align-items: center;
	cursor: pointer;
}
.view-insta-btn:hover {
	color: #54c3f2;
	text-decoration: none;
	opacity: 0.9;
}
.instagram-link-cards {
	transition: 0.3s ease;
	z-index: 0;
}
.instagram-link-cards:not(:first-child) {
	margin-left: -2.5rem;
}
.instagram-link-cards:hover {
	transform: rotate(-5deg);
}
.instagram-link-box {
	padding: 0 10px 20px 10px;
}
.user-area {
	padding-left: 65px;
	padding-right: 20px;
}
.content-area {
	/* padding-left: 65px;
	padding-right: 0px; */
	min-width: 735px;
}
@media (max-width: 800px) {
	.content-area {
		min-width: 100%;
	}
}

.tooltip-container {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.tooltip {
	visibility: hidden;
	width: 120px;
	background-color: black;
	color: white;
	text-align: center;
	border-radius: 6px;
	padding: 5px 0;
	position: absolute;
	z-index: 1;
	bottom: 110%;
	left: 50%;
	margin-left: -60px; /* Offset the width of the tooltip by half to center it */
	opacity: 0;
	transition: opacity 0.3s;
}

.tooltip-right {
	visibility: hidden;
	width: 120px;
	background-color: black;
	color: white;
	text-align: center;
	border-radius: 6px;
	padding: 5px 0;
	position: absolute;
	z-index: 9999;
	bottom: 88%;
	left: 50%;
	margin-left: -60px;
	opacity: 0;
	transition: opacity 0.3s;
}
.tooltip-rightt {
	visibility: hidden;
	width: 120px;
	background-color: black;
	color: white;
	text-align: center;
	border-radius: 6px;
	padding: 5px 0;
	position: absolute;
	z-index: 10;
	top: 15%;
	left: 50%;
	margin-left: 10px;
	transform: translateY(-50%);
	opacity: 0;
	transition: opacity 0.3s;
}
.tooltip-center {
	visibility: hidden;
	width: 120px;
	background-color: black;
	color: white;
	text-align: center;
	border-radius: 6px;
	padding: 5px 0;
	position: absolute;
	z-index: 10;
	top: 15%;
	left: 50% 50%;
	margin-left: 10px;
	transform: translateY(-50%);
	opacity: 0;
	transition: opacity 0.3s;
}
.tooltip-container:hover .tooltip-right,
.tooltip-container:hover .tooltip-rightt,
.tooltip-container:hover .tooltip,
.tooltip-container:hover .tooltip-center {
	visibility: hidden;
	opacity: 0;
	display: none;
}

@media (min-width: 678px) {
	.tooltip-container:hover .tooltip-right,
	.tooltip-container:hover .tooltip-rightt,
	.tooltip-container:hover .tooltip,
	.tooltip-container:hover .tooltip-center {
		visibility: visible;
		opacity: 1;
		display: block;
	}
}

.logo {
	max-width: 350px;
	min-width: 150px;
	max-height: 350px;
	min-height: 150px;
}

.bg-my {
	background-color: #0f2465 !important;
}

.my-card {
	border-radius: 24px;
}

.inverted {
	filter: invert(100%) drop-shadow(0 0 5px #000);
	height: 115px;
}

.invertedd {
	filter: invert(100%) drop-shadow(0 0 5px #000);
}

.logo-top {
	width: 300px;
	height: 168px;
}

.my-margin {
	margin-block: -10px;
}
@media screen and (max-width: 768px) {
	.my-ptn {
		top: 70%;
		left: 0;
	}
}

.bg-hvr {
	border: 1px solid #c0a96878;
	background-color: #1e2451b6;
	transition: all 0.3s ease-in-out;
}

.bg-hvr:hover {
	border: 1px solid var(--gold-color);
	background-color: #121634e7;
}
* {
	scrollbar-width: none; /* Funziona su Firefox */
}

/* Nasconde la scrollbar per tutti gli elementi e previene lo scrolling su Webkit (Chrome, Edge, Safari) */
*::-webkit-scrollbar {
	display: none; /* Funziona su Chrome, Edge, Safari */
}

.point {
	cursor: pointer;
}

@keyframes rotate {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
.rotate {
	animation: rotate 0.5s linear;
}

@keyframes clickScale {
	0%,
	100% {
		transform: scale(1);
	}
	25% {
		transform: scale(0.95);
		padding: 5px;
	}
	50% {
		transform: scale(1);
	}
	75% {
		transform: scale(1.05);
		padding: -5px;
	}
}
@keyframes clickScalePref {
	0%,
	100% {
		transform: scale(1);
	}
	25% {
		transform: scale(0.6);
	}
	50% {
		transform: scale(1);
	}
	75% {
		transform: scale(1.4);
	}
}
.pst-trophy {
	position: fixed;
	top: 100px;
	right: 10px;
	z-index: 9999;
}
.trophy {
	height: 50px;
	width: 50px;
	cursor: pointer;
}
.animate {
	animation: clickScalePref 0.5s ease-in-out forwards;
}

.favotite:active {
	animation: clickScalePref 0.5s ease-in-out forwards;
}
/*
.img-canvas {
	height: 50px;
	width: 50px;
	position: fixed;
	top: 25px;
	 right: 0; 
	transition: transform 0.5s ease;
	z-index: 9999;
}

@media screen and (min-width: 768px) {
	.img-canvas {
		position: absolute;
		height: 30px;
		width: 30px;
	}
	.pst-trophy {
		position: absolute;
		top: 80px;
	}
	.trophy {
		height: 30px;
		width: 30px;
	}
}
@media screen and (min-width: 1128px) {
	.img-canvas {
		position: absolute;
		height: 35px;
		width: 35px;
		right: 5px;
	}
	.pst-trophy {
		position: absolute;
		top: 80px;
		right: 5px;
	}
	.trophy {
		height: 40px;
		width: 40px;
	}
} */

.offcanvas.offcanvas-start {
	width: 250px;
}

.carousel-control-prev,
.carousel-control-next {
	display: none;
}
.custom-offcanvas-bg {
	background-color: var(--sgblu-color);
}

.img-title-canvas {
	object-fit: contain;
	width: 150px;
}
.img-title-canvas-squad {
	object-fit: contain;
	width: 100px;
}
.Modal {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1000;
	background-color: var(--sgblu-color);
	padding: 15px;
	border-radius: 25px;
}
/* .Modal-evento {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

} */
.Modal-evento {
	position: fixed;
	top: 40%;
	left: 50%;
	transform: translate(-50%, -40%);
	transition: all 0.5s ease-out; /* Tempo di animazione */
	z-index: 1000;
	background-color: var(--sgblu-color);
	padding: 15px;
	border-radius: 25px;
	border: 1px solid var(--gold-color);
}
#evento.Modal-evento {
	position: fixed;
	top: 40%;
	left: 50%;
	transform: translate(-50%, -40%);
	transition: all 0.5s ease-out;
	z-index: 1000;
	background-color: transparent;
	padding: 0;
	/* border-radius: 25px; */
	border: 0;
}
#evento.Modal-evento:focus-visible {
	border: 0 !important;
}
.clsf-marc {
	height: 500px;
	overflow-y: auto;
}

.Modal-evento.entered {
	transform: translate(-50%, -50%); /* Posizione finale */
}
.Modal {
	width: 90%;
}
.Modal-evento {
	width: 90%;
}
@media screen and (min-width: 768px) {
	.Modal {
		width: 50%;
	}
	.Modal-evento {
		width: 50%;
	}
}

.Overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 1);
	z-index: 9999;
	transition: all 0.5s ease-in-out;
}

.download {
	position: relative;
	overflow: visible;
	width: 100px;
	height: 35px;
}

.download-arrow {
	position: absolute;
	left: 50%;
	bottom: 0;
	transform: translateX(-50%) translateY(100%);
}

.confirm,
.confirm:hover,
.confirm:focus,
.confirm:focus-within {
	color: var(--gold-color);
}
.back,
.back:hover,
.back:focus,
.back:focus-within {
	color: rgb(231, 29, 29);
}

.bg-doc {
	background-color: #191e47bd;
}

.card-doc {
	border-radius: 25px;
}

.gold {
	color: var(--gold-color) !important;
}
/* Stile base per form */
.bg-form {
	background-color: #ffffff;
	margin-inline: auto;
	border-radius: 25px;
	padding-block: 15px;
}

.i-frame-height {
	height: 250px;
}

/* Breakpoints per smartphone (dispositivi molto piccoli) */
@media screen and (max-width: 480px) {
	.bg-form {
		width: 100%;
	}
	.i-frame-loaded {
		height: 4700px;
	}
}
@media screen and (min-width: 481px) and (max-width: 558px) {
	.bg-form {
		width: 85%;
	}
	.i-frame-loaded {
		height: 4700px;
	}
}
@media screen and (min-width: 559px) and (max-width: 589px) {
	.bg-form {
		width: 85%;
	}
	.i-frame-loaded {
		height: 4650px;
	}
}

/* Breakpoints per smartphone grandi fino a tablet (dispositivi piccoli) */
@media screen and (min-width: 590px) and (max-width: 619px) {
	.bg-form {
		width: 85%;
	}
	.i-frame-loaded {
		height: 4550px;
	}
}

/* Breakpoints per smartphone grandi fino a tablet (dispositivi piccoli) */
@media screen and (min-width: 620px) and (max-width: 650px) {
	.bg-form {
		width: 85%;
	}
	.i-frame-loaded {
		height: 4300px;
	}
}

@media screen and (min-width: 651px) and (max-width: 680px) {
	.bg-form {
		width: 85%;
	}
	.i-frame-loaded {
		height: 4250px;
	}
}

/* Breakpoints per smartphone grandi fino a tablet (dispositivi piccoli) */
@media screen and (min-width: 681px) and (max-width: 768px) {
	.bg-form {
		width: 85%;
	}
	.i-frame-loaded {
		height: 4250px;
	}
}

/* Breakpoints per tablet fino a piccoli desktop (dispositivi medi) */
@media screen and (min-width: 769px) and (max-width: 1024px) {
	.bg-form {
		width: 75%;
	}
	.i-frame-loaded {
		height: 4230px;
	}
}

/* Breakpoints per desktop (dispositivi grandi) */
@media screen and (min-width: 1025px) and (max-width: 1200px) {
	.bg-form {
		width: 60%;
	}
	.i-frame-loaded {
		height: 4220px;
	}
}

/* Breakpoints per desktop grandi (dispositivi molto grandi) */
@media screen and (min-width: 1201px) {
	.bg-form {
		width: 50%;
	}
	.i-frame-loaded {
		height: 4220px;
	}
}

.back-arrow,
.back-arrow:hover,
.back-arrow:focus,
.back-arrow:focus-within {
	color: var(--gold-color);
	text-decoration: underline;
	z-index: 1550;
}
@media (min-width: 968px) {
	.back-arrow {
		font-size: 24px;
	}
}
@keyframes slideBackground {
	from {
		background-position: 0 0;
	}
	to {
		background-position: 100% 0;
	}
}

.nav-links {
	color: white !important;
	font-size: 20px;
	padding: 0.5rem 1rem;
	transition:
		all 0.3s ease-in-out,
		background-position 0.5s ease-in-out; /* Transizione per il cambio di colore e posizione dello sfondo */
	border-radius: 25px;
	padding-inline: 16px;
	padding-block: 7.5px;
	/* background: linear-gradient(90deg, rgba(40, 49, 111, 1) 0%, rgba(39, 49, 123, 1) 50%, rgba(40, 49, 111, 1) 100%); */
	background-size: 200%;
}

@media (max-width: 768px) {
	.nav-links {
		font-size: 16px;
	}
}

.nav-links:hover {
	background-color: #28316f;
}

.nav-links.active {
	color: var(--gold-color) !important; /* Colore oro per i link attivi */
	background-position: 100% 0; /* Imposta la posizione finale dello sfondo */
	background-color: #141629;
}

.nav-link.animate {
	animation: pulse 0.35s;
}

@keyframes pulse {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.1);
	}
	100% {
		transform: scale(1);
	}
}

.btn {
	--bs-btn-border-width: 0;
	--bs-btn-border-color: transparent;
	--bs-btn-border-radius: 0;
}
.dropdown-toggle-custom::after {
	display: none;
}

/* Stile comune per il menu dropdown */
.dropdown-menu-custom {
	position: absolute !important;
	left: 0 !important;
	right: 0 !important;
	z-index: 1000;
	background-color: #191e46;
	border: none;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Stile specifico per i dispositivi mobili (apertura verso l'alto) */
@media (max-width: 991px) {
	.dropdown-menu-custom {
		bottom: 50px !important;
		top: auto !important;
	}
}

/* Stile specifico per i desktop (apertura verso il basso) */
@media (min-width: 992px) {
	.dropdown-menu-custom {
		top: 50px !important;
		bottom: auto !important;
	}
}

.scroll-top {
	position: fixed;
	bottom: 20px;
	right: 20px;
	color: var(--gold-color);
	border-radius: 50%;
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	z-index: 1000;
	opacity: 0;
}
@media (max-width: 768px) {
	.scroll-top {
		bottom: 30px;
	}
}
.home-round {
	border-radius: 25px;
	border: 1px solid #1a2049;
}
.banner {
	border-color: var(--gold-color);
	background-color: #121634d2;
}
.event {
	border-color: var(--gold-color);
	background-color: #121634;
}
.team-card {
	border-color: var(--gold-color);
}
.img-canvas-icon {
	width: 40px;
	height: 40px;
}
.img-canvas-icon-maglia {
	width: 35px;
	height: auto;
}

.instagram-add {
	height: 500px;
	width: 500px;
}

.countdown {
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--gold-color);
}

.countdown span {
	background-color: #ffffff46;
	font-size: 16px;
	padding-inline: 10px;
	border-radius: 25px;
	margin-top: 5px;
	font-weight: 600;
}
/* 
.logo-squadra {
	opacity: 0.2;
}
*/

/* Stili di base per mobile */
.logo-casa,
.logo-ospite {
	width: 35px;
	height: 35px;
	margin-inline: auto;
	object-fit: contain;
	margin-block: auto;
}
.result-section {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.result {
	font-size: 20px; /* più piccolo per mobile */
	font-family: "Roboto", monospace;
}

.time {
	font-size: 16px; /* più piccolo per mobile */
	font-family: "Roboto", monospace;
}

.highlights {
	height: 20px; /* più piccolo per mobile */
	width: 20px; /* più piccolo per mobile */
}
@media (min-width: 268px) {
	.result {
		font-size: 12px; /* intermedio per tablet */
	}
	.result {
		font-size: 10px; /* intermedio per tablet */
	}
}
@media (min-width: 350px) {
	.result {
		font-size: 18px; /* intermedio per tablet */
	}
	.logo-casa,
	.logo-ospite {
		width: 60px;
		height: 60px;
		margin-inline: auto;
	}
}
@media (min-width: 568px) {
	.logo-casa,
	.logo-ospite {
		width: 120px;
	}
	.result-section {
		gap: 15px; /* intermedio per tablet */
	}

	.result {
		font-size: 15px; /* intermedio per tablet */
	}

	.time {
		font-size: 22px; /* intermedio per tablet */
	}

	.highlights {
		height: 25px; /* intermedio per tablet */
		width: 25px; /* intermedio per tablet */
	}
}
/* Stili per tablet (min-width 768px) */
@media (min-width: 768px) {
	.logo-casa,
	.logo-ospite {
		width: 110px;
		height: 110px;
	}
	.result-section {
		gap: 15px; /* intermedio per tablet */
	}

	.result {
		font-size: 15px; /* intermedio per tablet */
	}

	.time {
		font-size: 24px; /* intermedio per tablet */
	}

	.highlights {
		height: 25px; /* intermedio per tablet */
		width: 25px; /* intermedio per tablet */
	}
}

/* Stili per schermi intermedi tra tablet e desktop (min-width 992px) */
@media (min-width: 992px) {
	.logo-casa,
	.logo-ospite {
		width: 160px;
		height: 160px;
	}
	.result-section {
		gap: 18px; /* avvicinandoci alle misure da desktop */
	}

	.result {
		font-size: 35px; /* avvicinandoci alle misure da desktop */
	}

	.time {
		font-size: 26px; /* avvicinandoci alle misure da desktop */
	}

	.highlights {
		height: 32px; /* avvicinandoci alle misure da desktop */
		width: 32px; /* avvicinandoci alle misure da desktop */
	}
}

/* Stili per desktop (min-width 1200px) */
@media (min-width: 1200px) {
	.logo-casa,
	.logo-ospite {
		width: 150px;
		height: 150px;
	}
	.result-section {
		gap: 20px; /* misura definitiva per desktop */
	}

	.result {
		font-size: 40px; /* misura definitiva per desktop */
	}

	.time {
		font-size: 28px; /* misura definitiva per desktop */
	}

	.highlights {
		height: 35px; /* misura definitiva per desktop */
		width: 35px; /* misura definitiva per desktop */
	}
}

input.data-picker,
input.data-picker:active,
input.data-picker:focus,
input.data-picker:focus-visible,
input.data-picker:focus-within {
	background-color: var(--sgblu-color) !important;
	color: var(--gold-color) !important;
	border-radius: 25px !important;
	padding: 12px !important;
	border-color: var(--gold-color) !important;
}
input.data-picker:focus-visible {
	outline-offset: 0;
	outline: 0;
}

.react-datepicker__month-container {
	background-color: var(--sgblu-color);
	border: var(--gold-color) !important;
}

.react-datepicker__day {
	color: var(--gold-color) !important;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
	cursor: default;
	color: #b8b8b8 !important;
}

.react-datepicker__header {
	background-color: var(--sgblu-color) !important;
}

.react-datepicker__navigation-icon::before {
	border-color: var(--gold-color) !important;
}

.react-datepicker__current-month {
	color: var(--gold-color) !important;
}

.react-datepicker__day-name {
	color: var(--gold-color) !important;
}

.react-datepicker {
	font-family: "Helvetica Neue", helvetica, arial, sans-serif;
	font-size: 0.8rem;
	background-color: #fff;
	color: #000;
	border: 1px solid var(--gold-color) !important;
	border-radius: 0.3rem;
	display: inline-block;
	position: relative;
	line-height: initial;
}

.reservati {
	font-size: 12px;
}
.cookie-banner {
	background: #191e47 !important;
	color: #c0a868 !important;
	text-align: center !important;
	padding: 10px !important;
	margin-inline: auto !important;
	z-index: 9999 !important;
}

/* Stili per i bottoni */
.button-container {
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
	gap: 25px !important;
	margin-top: 10px !important;
}

.cookie-accept-btn,
.cookie-decline-btn {
	font-size: 14px !important;
	font-weight: bold !important;
	padding: 10px 20px !important; /* Più padding per una migliore leggibilità */
	border: none !important; /* Rimuovi bordi indesiderati */
	cursor: pointer !important; /* Stile del cursore per indicare cliccabilità */
	transition: background-color 0.3s !important; /* Transizione fluida per hover */
}
.cookie-accept-btn {
	background-color: var(--sgblu-color) !important;
	color: #ffffff !important;
}

.cookie-accept-btn:hover {
	background-color: #272f6d !important;
	color: #b29c60 !important;
}

.cookie-decline-btn {
	background-color: rgb(187, 15, 15) !important;
	color: #ffffff !important;
}

.cookie-decline-btn:hover {
	background-color: rgb(204, 16, 16) !important;
	color: #b29c60 !important;
}
#rcc-confirm-button {
	display: none !important;
}

/* Responsive adjustments */
@media (max-width: 768px) {
	.button-container {
		flex-direction: column !important; /* Stack buttons vertically on smaller screens */
	}

	.cookie-accept-btn,
	.cookie-decline-btn {
		width: 100% !important; /* Full width buttons */
		margin-top: 10px !important; /* Spazio tra i bottoni */
	}
}
/* Sovrascrivi lo stile per la progress bar */
.Toastify__progress-bar {
	background: var(--sgblu-color) !important;
}
.Toastify__progress-bar--wrp {
	background: #323c8b !important;
}
.Toastify__progress-bar--success,
.Toastify__progress-bar--error {
	background: #ffffff3c !important;
}

.Toastify__toast-icon {
	color: #b29c60 !important;
}

.Toastify__zoom-enter.Toastify--animate-icon svg {
	fill: #b29c60 !important;
}

.Toastify__progress-bar--error {
	background: #b29c60 !important;
}

/* Stile per l'icona di errore */
.Toastify__toast-icon.Toastify__toast-icon--error {
	color: #b29c60 !important;
}

/* Stile per l'animazione dell'icona di errore se presente */
.Toastify__zoom-enter.Toastify--animate-icon.Toastify__toast-icon--error svg {
	fill: #b29c60 !important;
}

@media (max-width: 768px) {
	.my-mobile-button {
		position: absolute;
		bottom: 10px;
		right: -50px;
		transform: translateX(-50%);
	}
}

.cookie-banner > div[style*="margin: 15px;"] {
	margin: 0 !important;
}

@keyframes fadeIn {
	from {
		opacity: 0.2;
	}
	to {
		opacity: 1;
	}
}

.fade-in-animation {
	animation: fadeIn 1.5s ease-in-out;
}

@media (max-width: 978px) {
	.fade-in-animation {
		animation: fadeIn 1s ease-in-out;
	}
}

.icon-lang {
	height: 34px;
	width: 34px;
	fill: #fff;
	transition: all 0.3s ease-in-out;
}

.icon-lang:hover {
	fill: var(--gold-color);
}
/* .icon-lang-container {
	position: absolute;
	top: 50%;
	left: 10px;
} */

.icon-lang-container .show {
	animation: fadeIn 0.5s ease-in-out;
}

@keyframes skeleton-loading {
	0% {
		background-color: #eee;
	}
	50% {
		background-color: #ddd;
	}
	100% {
		background-color: #eee;
	}
}

.skeleton-block {
	animation: skeleton-loading 1s linear infinite;
}

.bg-sgblu {
	background-color: var(--sgblu-color);
}
.carousel-media {
	border-top-right-radius: 35px;
	border-top-left-radius: 35px;
	background-color: #202b5b;
	height: 700px;
	width: auto;
	border: 0;
}

.custom-offcanvas-bg.offcanvas.offcanvas-start.show {
	z-index: 1600;
}

.bg-maglia {
	border-top-right-radius: 35px;
	border-top-left-radius: 35px;
	background-color: #202b5b;
	border-bottom: 2px solid var(--gold-color);
}

@media (min-width: 992px) {
	.carousel-media {
		border-top-left-radius: 35px;
		border-top-right-radius: 35px;
		border-bottom-left-radius: 0;
		height: 500px;
		width: auto;
	}
	.bg-maglia {
		border-bottom-left-radius: 35px;
		border-top-right-radius: 35px;
		border-top-right-radius: 0;
		background-color: #202b5b;
		border-right: 2px solid var(--gold-color);
		border-bottom: 0;
	}
}
@media (max-width: 468px) {
	.carousel-media {
		border-top-left-radius: 35px;
		border-top-right-radius: 35px;
		border-bottom-left-radius: 0;
		height: 300px;
		width: 300px;
	}
	.bg-maglia {
		border-top-left-radius: 35px;
		border-top-right-radius: 35px;
		border-bottom-left-radius: 0;
		background-color: #202b5b;
		border-right: 0;
	}
}
.merch-card {
	border-radius: 35px;
	background-color: #fff;
	border: 2px solid var(--gold-color);
}

#maglia .form-control {
	background-color: rgba(255, 255, 255);
	font-size: 13px;
	border-radius: 25px;
	color: rgb(45, 45, 45) !important;
}

#maglia .form-control:focus {
	border-color: black;
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
	color: rgb(45, 45, 45) !important;
}

#maglia .form-control::placeholder {
	color: rgb(45, 45, 45) !important;
	opacity: 1;
}

#maglia .form-control:focus::placeholder {
	color: rgb(45, 45, 45) !important;
}
.merch-sub {
	padding: 10px;
	border: none;
	outline: none;
	transition: box-shadow 0.1s ease;
}

.merch-sub:active {
	box-shadow: inset 0px 0px 3px 3px var(--sgblu-color);
}
.my-fs {
	font-size: 12px;
}
.my-fs-fanta {
	font-size: 25px;
}
.my-fs-xs {
	font-size: 10px;
}
@media (min-width: 768px) {
	.my-fs-xs {
		font-size: 18px;
	}
	.my-fs {
		font-size: 20px;
	}
}
.chart-pst {
	position: absolute;
	top: 15px;
	right: 15px;
	z-index: 1000;
	font-size: 12px;
}

.policy-pst {
	position: absolute;
	right: -45px;
	bottom: -50px;
}
@media (min-width: 568px) {
	.policy-pst {
		right: -100px;
	}
}
@media (min-width: 768px) {
	.policy-pst {
		right: -120px;
	}
}

@media (min-width: 978px) {
	.policy-pst {
		right: 0px;
	}
}
.promo-video {
	width: 100%;
	height: 100%;
}
.phone-card {
	border-radius: 25px;
}
.poster {
	width: 300px;
}
@media (min-width: 768px) {
	.poster {
		width: 400px;
	}
}
.result-home {
	position: absolute;
	top: 50%;
	right: 50%;
}
.text-underline {
	text-decoration: underline;
}
.home-squad {
	height: 150px;
	width: 150px;
}
.preview-card {
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	background-size: cover;
	background-position: center;
	transition: opacity 0.5s ease;
	margin-bottom: 16px;
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
.hvr {
	transition:
		background-color 0.3s ease-in-out,
		padding 0.3s ease-in-out,
		border-radius 0.3s ease-in-out;
	color: white;
}
.hvr:hover {
	transform: scale(1.02);
	background-color: #28293085;
	padding: 5px 5px 5px 10px;
	border-radius: 25px;
	color: var(--gold-color);
}
.hvr2 {
	transition:
		background-color 0.3s ease-in-out,
		padding 0.3s ease-in-out,
		border-radius 0.3s ease-in-out,
		transform 0.3s ease-in-out,
		box-shadow 0.3s ease-in-out;
	color: white;
}
.hvr2:hover {
	transform: scale(1.03);
	background-color: #28293085;
	color: var(--gold-color);
	-webkit-box-shadow: 5px 5px 10px 7px rgba(0, 0, 0, 0.24);
	box-shadow: 5px 5px 10px 7px rgba(0, 0, 0, 0.24);
}
.hvr3 {
	transition:
		background-color 0.3s ease-in-out,
		padding 0.3s ease-in-out,
		border-radius 0.3s ease-in-out;
	color: white;
	border: 1px solid #ffffff7a;
}
.hvr3:hover {
	border: 1px solid var(--gold-color);
	background-color: #28293085;
	color: var(--gold-color);
}
.hvr-logo {
	transition: transform 0.3s ease-in-out;
}
.hvr:hover .hvr-logo {
	transform: scale(1.1);
}
.drop-squad {
	position: absolute;
	top: 10px;
	left: 10px;
}

.drop-no::after {
	display: none;
}

.drop-menu-squad {
	background-color: var(--sgblu-color);
	border-color: var(--gold-color);
	color: #fff;
}

.drop-menu-squad .dropdown-item {
	color: #fff;
	transition:
		background-color 0.3s ease-in-out,
		padding 0.3s ease-in-out,
		border-radius 0.3s ease-in-out;
}

.drop-menu-squad .dropdown-item:hover,
.drop-menu-squad .dropdown-item:focus {
	background-color: #10142e;
	color: var(--gold-color);
	padding: 11px 10px;
}

.drop-menu-squad .dropdown-item:first-child:hover,
.drop-menu-squad .dropdown-item:first-child:focus {
	border-top-left-radius: 0.35em;
	border-top-right-radius: 0.35em;
}

.drop-menu-squad .dropdown-item:last-child:hover,
.drop-menu-squad .dropdown-item:last-child:focus {
	border-bottom-left-radius: 0.35em;
	border-bottom-right-radius: 0.35em;
}

.squad-social {
	position: absolute;
	bottom: 3px;
	right: 15px;
}
.col-img-squad {
	border-left: 1px outset var(--gold-color);
}
@media (max-width: 991px) {
	.col-img-squad {
		border-left: 0;
	}
}
.slick-list {
	padding: 0 !important;
}
.slick-slide {
	margin-inline: 10px;
}
.logo-clsf {
	filter: drop-shadow(0 5px 5px #000000e3);
	height: 50px;
	width: 50px;
	object-fit: contain;
}
.logo-clsf-mini {
	height: 130px;
	width: 130px;
	object-fit: contain;
	padding: 10px;
}
@media (min-width: 768px) {
	.logo-clsf {
		height: 65px;
		width: 65px;
	}
	.logo-clsf-mini {
		height: 155px;
		width: 155px;
		object-fit: contain;
		padding: 0;
	}
}
.marc-numero {
	color: #fff;
}
/* Dimensioni per desktop (min-width: 992px) */
@media (min-width: 992px) {
	.logo-clsf {
		height: 80px;
		width: 80px;
	}
}

#clsf .table.clsf .small-col {
	width: 25px !important;
}

#clsf .table.clsf .large-col {
	width: 210px !important;
}

#clsf .table.clsf .equal-col {
	width: 50px !important;
}

.table-responsive {
	border: 2px solid var(--gold-color);
	border-radius: 25px;
}

#clsf .table {
	--bs-table-color-type: initial;
	--bs-table-bg-type: initial;
	--bs-table-color-state: initial;
	--bs-table-bg-state: initial;
	--bs-table-color: var(--gold-color);
	--bs-table-bg: #171c41;
	--bs-table-border-color: transparent;
	--bs-table-accent-bg: transparent;
	--bs-table-striped-color: var(--gold-color);
	--bs-table-striped-bg: #202b5b7f;
	--bs-table-active-color: var(--gold-color);
	--bs-table-active-bg: #18204429;
	--bs-table-hover-color: #d4bb73;
	--bs-table-hover-bg: #171c41;
	width: 100%;
	margin-bottom: 0;
	vertical-align: top;
	border-color: transparent;
}
/* Imposta i bordi di default per tutte le celle */
#clsf .table th,
#clsf .table td {
	border-right: 1px solid var(--gold-color);
	border-bottom: 1px solid var(--gold-color);
}

/* Rimuovi il bordo destro dalla ultima colonna */
#clsf .table th:last-child,
#clsf .table td:last-child {
	border-right: none;
}

/* Rimuovi il bordo inferiore dall'ultima riga */
#clsf .table tr:last-child td {
	border-bottom: none;
}
.goal-icon {
	height: 15px;
	width: 15px;
	margin-block: auto;
}
.podium {
	display: flex;
	justify-content: center;
	align-items: flex-end;
	margin-top: 20px;
}
.adverting {
	font-size: 11px;
}
.h2-list {
	font-size: 20px;
}
@media (max-width: 768px) {
	.h2-list {
		font-size: 15px;
	}
}

@media (max-width: 468px) {
	.squad-name {
		font-size: 12px;
	}
	#clsf .table tr {
		font-size: 12px;
	}
}
@media (max-width: 468px) {
	.title-clsf {
		font-size: 16px;
	}
}
.score {
	font-size: 40px;
}
.sticky {
	position: sticky;
	top: -5px;
}
.time-golden {
	color: var(--gold-color);
}
.table > :not(caption) > * > * {
	padding: 0.5rem 0.2rem;
	color: inherit;
	background-color: var(--bs-table-bg);
	border-bottom-width: var(--bs-border-width);
	box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}
th {
	color: var(--gold-color) !important;
}
:focus-visible {
	outline: 0;
}
.logo-carosello {
	width: 250px;
	height: 250px;
	object-fit: contain;
	filter: drop-shadow(0 5px 5px #000000e3);
	transition: all 0.3s ease-in-out;
}
.hvr3:hover .logo-carosello {
	transform: scale(1.1);
}
.insta-dtls {
	width: 30px;
}
.insta-pst {
	top: 15px;
	position: absolute;
	left: 10px;
}
.logo-dtls {
	height: 230px;
	width: 230px;
	object-fit: contain;
	margin-inline: auto;
	padding: 5px;
	filter: drop-shadow(0 5px 5px #000000e3);
}
.bodyNoScroll {
	overflow: hidden;
	height: 100%;
}
.icon-pst {
	height: 30px;
	width: 30px;
	color: white;
}
.icon-pst:hover {
	color: var(--gold-color);
}
.threedot {
	color: white;
	z-index: 9999;
}
.threedot:hover {
	color: var(--gold-color);
}
.giocatori-list {
	height: 450px;
	overflow-y: auto;
}
.name-details {
	font-size: 15px;
}
.giglio-pst {
	top: 12px;
	position: absolute;
	right: 10px;
}
@media (min-width: 768px) {
	.giglio-pst {
		top: 15px;
		position: absolute;
		right: -5px;
	}
}
.pref-stroke {
	transition: all 0.2s ease-in-out;
}
svg:hover .pref-stroke {
	stroke: var(--gold-color);
	stroke-width: 4.5px;
}
@keyframes strokeAnimation {
	0% {
		stroke-opacity: 0.4;
	}
	50% {
		stroke-opacity: 1;
	}
	100% {
		stroke-opacity: 0.4;
	}
}
.animatedStroke {
	animation: strokeAnimation 1.5s infinite;
}
.mobilenavbar {
	position: fixed;
	bottom: 10px;
	left: 0;
	right: 0;
	z-index: 1500;
	transition: transform 0.3s ease-in-out;
}
.hidden {
	transform: translateY(100%);
	transition: transform 0.3s ease-in-out; /* Transizione per la discesa */
}
.news-ticker {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1500;
	overflow: hidden;
	white-space: nowrap;
	transition: transform 0.3s ease-in-out;
}

.news-ticker.hidden {
	transform: translateY(-100%);
}

.news-ticker.shown {
	transform: translateY(0);
}

.news-ticker-inner {
	display: inline-block;
}

.news-item {
	display: inline-block;
	padding: 0 10px;
	color: #fff;
}

@media (min-width: 768px) {
	.mobilenavbar {
		position: sticky;
		bottom: auto;
		top: 10px;
	}
	.hidden {
		transform: translateY(-100%);
		transition: transform 0.3s ease-in-out; /* Transizione per la salita */
		top: 0;
	}
	.news-ticker {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		top: auto; /* Modifica per forzare il ticker delle news in basso */
	}
	.news-ticker.hidden {
		transform: translateY(100%); /* Modifica per nascondere il ticker delle news verso il basso */
		transition: transform 0.3s ease-in-out;
		bottom: 0;
	}
	.news-ticker.shown {
		transform: translateY(0); /* Modifica per mostrare il ticker delle news */
	}
}

.navbar {
	width: 100%;
}
.img-canvas-mini {
	height: 35px;
	width: 35px;
}
.border-gold {
	stroke: var(--gold-color);
}
.go2344853693 {
	background: var(--gold-color) !important;
}
.partita-hidden {
	opacity: 0;
	transform: translateY(20px);
	transition:
		opacity 0.5s ease-out,
		transform 0.5s ease-out;
}

.partita-visible {
	opacity: 1;
	transform: translateY(0);
	transition-delay: var(--delay); /* Utilizza una variabile CSS per il delay */
}
.team-entry {
	opacity: 0;
	transition:
		transform 0.5s ease-out,
		opacity 0.5s ease-out;
}

.left-entry {
	transform: translateX(-100%);
	animation: slideInFromLeft 0.5s forwards;
}

.right-entry {
	transform: translateX(100%);
	animation: slideInFromRight 0.5s forwards;
}
@keyframes slideInFromLeft {
	to {
		transform: translateX(0%);
		opacity: 1;
	}
}

@keyframes slideInFromRight {
	to {
		transform: translateX(0%);
		opacity: 1;
	}
}
.logo-squad-create {
	height: 35px;
	width: 35px;
	object-fit: contain;
}
@media (min-width: 768px) {
	.logo-squad-create {
		height: 50px;
		width: 50px;
	}
}
.input-custom {
	color: white !important;
	background-color: transparent;
}

.input-custom::placeholder {
	color: #ccc;
}
.form-control.input-custom:focus {
	border-color: transparent !important;
	outline: 0 !important;
	box-shadow: none !important;
	border-bottom: 1px solid #ccc !important;
}
.form-control.input-custom {
	border-color: transparent !important;
	outline: 0 !important;
	box-shadow: none !important;
	border-bottom: 1px solid #ccc !important;
}
.button-custom {
	transition: opacity 0.3s ease;
	opacity: 1;
}
.button-custom:disabled {
	opacity: 0.5;
}
@media (min-width: 768px) {
	.button-role {
		width: 220px;
	}
}
.number-merch {
	width: 50px;
}
.bg-campo {
	background: linear-gradient(
		0deg,
		rgba(162, 162, 162, 82%) 0%,
		rgba(154, 153, 153, 82%) 1%,
		rgba(156, 156, 156, 82%) 2%,
		rgba(162, 162, 162, 82%) 3%,
		rgba(154, 153, 153, 82%) 4%,
		rgba(156, 156, 156, 82%) 5%,
		rgba(154, 153, 153, 82%) 6%,
		rgba(162, 162, 162, 82%) 7%,
		rgba(156, 156, 156, 82%) 8%,
		rgba(162, 162, 162, 82%) 9%,
		rgba(154, 153, 153, 82%) 10%,
		rgba(156, 156, 156, 82%) 11%,
		rgba(154, 153, 153, 82%) 12%,
		rgba(162, 162, 162, 82%) 13%,
		rgba(156, 156, 156, 82%) 14%,
		rgba(162, 162, 162, 82%) 15%,
		rgba(154, 153, 153, 82%) 16%,
		rgba(156, 156, 156, 82%) 17%,
		rgba(162, 162, 162, 82%) 18%,
		rgba(154, 153, 153, 82%) 19%,
		rgba(156, 156, 156, 82%) 20%,
		rgba(154, 153, 153, 82%) 21%,
		rgba(162, 162, 162, 82%) 22%,
		rgba(156, 156, 156, 82%) 23%,
		rgba(162, 162, 162, 82%) 24%,
		rgba(154, 153, 153, 82%) 25%,
		rgba(156, 156, 156, 82%) 26%,
		rgba(154, 153, 153, 82%) 27%,
		rgba(162, 162, 162, 82%) 28%,
		rgba(156, 156, 156, 82%) 29%,
		rgba(162, 162, 162, 82%) 30%,
		rgba(154, 153, 153, 82%) 31%,
		rgba(156, 156, 156, 82%) 32%,
		rgba(154, 153, 153, 82%) 33%,
		rgba(162, 162, 162, 82%) 34%,
		rgba(156, 156, 156, 82%) 35%,
		rgba(162, 162, 162, 82%) 36%,
		rgba(154, 153, 153, 82%) 37%,
		rgba(156, 156, 156, 82%) 38%,
		rgba(154, 153, 153, 82%) 39%,
		rgba(162, 162, 162, 82%) 40%,
		rgba(156, 156, 156, 82%) 41%,
		rgba(162, 162, 162, 82%) 42%,
		rgba(154, 153, 153, 82%) 43%,
		rgba(156, 156, 156, 82%) 44%,
		rgba(154, 153, 153, 82%) 45%,
		rgba(162, 162, 162, 82%) 46%,
		rgba(156, 156, 156, 82%) 47%,
		rgba(162, 162, 162, 82%) 48%,
		rgba(154, 153, 153, 82%) 49%,
		rgba(156, 156, 156, 82%) 50%,
		rgba(154, 153, 153, 82%) 51%,
		rgba(162, 162, 162, 82%) 52%,
		rgba(156, 156, 156, 82%) 53%,
		rgba(162, 162, 162, 82%) 54%,
		rgba(154, 153, 153, 82%) 55%,
		rgba(156, 156, 156, 82%) 56%,
		rgba(154, 153, 153, 82%) 57%,
		rgba(162, 162, 162, 82%) 58%,
		rgba(156, 156, 156, 82%) 59%,
		rgba(162, 162, 162, 82%) 60%,
		rgba(154, 153, 153, 82%) 61%,
		rgba(156, 156, 156, 82%) 62%,
		rgba(154, 153, 153, 82%) 63%,
		rgba(162, 162, 162, 82%) 64%,
		rgba(156, 156, 156, 82%) 65%,
		rgba(162, 162, 162, 82%) 66%,
		rgba(154, 153, 153, 82%) 67%,
		rgba(156, 156, 156, 82%) 68%,
		rgba(154, 153, 153, 82%) 69%,
		rgba(162, 162, 162, 82%) 70%,
		rgba(156, 156, 156, 82%) 71%,
		rgba(162, 162, 162, 82%) 72%,
		rgba(154, 153, 153, 82%) 73%,
		rgba(156, 156, 156, 82%) 74%,
		rgba(154, 153, 153, 82%) 75%,
		rgba(162, 162, 162, 82%) 76%,
		rgba(156, 156, 156, 82%) 77%,
		rgba(162, 162, 162, 82%) 78%,
		rgba(154, 153, 153, 82%) 79%,
		rgba(156, 156, 156, 82%) 80%,
		rgba(154, 153, 153, 82%) 81%,
		rgba(162, 162, 162, 82%) 82%,
		rgba(156, 156, 156, 82%) 83%,
		rgba(162, 162, 162, 82%) 84%,
		rgba(154, 153, 153, 82%) 85%,
		rgba(156, 156, 156, 82%) 86%,
		rgba(154, 153, 153, 82%) 87%,
		rgba(162, 162, 162, 82%) 88%,
		rgba(156, 156, 156, 82%) 89%,
		rgba(162, 162, 162, 82%) 90%,
		rgba(154, 153, 153, 82%) 91%,
		rgba(156, 156, 156, 82%) 92%,
		rgba(154, 153, 153, 82%) 93%,
		rgba(162, 162, 162, 82%) 94%,
		rgba(156, 156, 156, 82%) 95%,
		rgba(162, 162, 162, 82%) 96%,
		rgba(154, 153, 153, 82%) 97%,
		rgba(156, 156, 156, 82%) 98%,
		rgba(154, 153, 153, 82%) 99%,
		rgba(162, 162, 162, 82%) 100%
	);
	border: 15px solid rgb(255 255 255 / 82%);
	position: relative;
}
.semicircle {
	width: 200px;
	height: 100px;
	background: #20275bd0;
	border-radius: 200px 200px 0 0;
	border-top: 15px solid #ffffffe9;
	border-left: 15px solid #ffffffe9;
	border-right: 15px solid #ffffffe9;
	overflow: hidden;
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
}
.semicircle-bottom {
	width: 200px;
	height: 100px;
	background: #20275bd0;
	border-bottom: 15px solid #ffffffe9;
	border-left: 15px solid #ffffffe9;
	border-right: 15px solid #ffffffe9;
	overflow: hidden;
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
}
.centro-role {
	font-size: 14px;
}
@media (min-width: 768px) {
	.semicircle,
	.semicircle-bottom {
		width: 400px;
		height: 200px;
	}
	.name {
		font-size: 18px;
	}
}
@media (max-width: 315px) {
	.centro-role {
		font-size: 10px;
	}
}
.player-number-fanta {
	position: absolute;
	color: #00000089;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	z-index: -1;
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.player-name-final,
.player-number-fanta-final {
	color: var(--sgblu-color);
}
.card.glass-card.hvr3.gap-3:hover .player-number-fanta,
.card.glass-card.hvr3.gap-3:hover .player-number-fanta-final,
.card.glass-card.hvr3.gap-3:hover .player-name,
.card.glass-card.hvr3.gap-3:hover .player-name-final {
	color: #fff;
}
.card.glass-card.hvr3.gap-3:hover .player-number-fanta-final,
.card.glass-card.hvr3.gap-3:hover .player-name-final {
	color: var(--gold-color);
}
.card.glass-card.hvr3.gap-3:hover .player-role {
	color: var(--gold-color);
}
.player-goal,
.player-number {
	position: absolute;
	color: #ffffff3d;
	top: 45%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	z-index: -1;
}
.player-role {
	color: #fff;
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.player-name {
	color: var(--gold-color);
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.squad-hvr:hover .player-name {
	color: #fff;
}

.player-goal.font-size-large {
	font-size: 200px;
}
@media (min-width: 768px) {
	.player-goal,
	.player-number,
	.player-number-fanta {
		position: absolute;
		top: 50%;
		left: 45%;
		transform: translate(-50%, -50%);
		z-index: -1;
	}
	.player-goal.font-size-large {
		font-size: 190px;
	}
}
.player-number,
.player-number-fanta {
	font-size: 80px;
}

.player-goal.font-size-medium {
	font-size: 170px;
}

.player-goal.font-size-small {
	font-size: 120px;
}
.b-argento {
	border: 1px solid silver;
}
.b-bronzo {
	border: 1px solid rgb(167, 117, 23);
}
.card-content {
	position: relative;
	z-index: 3;
}
.annuncio-merch {
	font-size: 13px;
}
.badge-pst {
	position: absolute;
	top: 15px;
	left: 15px;
	padding-inline: 15px;
	padding-block: 5px;
	background-color: #2b3479 !important;
	color: var(--gold-color);
	box-shadow: rgba(0, 0, 0, 0.463) -2px 2px 1px 0;
}
.bg-badge {
	background-color: var(--sgblu-color) !important;
}
.pst-coach {
	position: absolute;
	bottom: 10px;
	left: 25px;
	transform: rotate(270deg);
	transform-origin: bottom left;
	font-size: 20px;
}
@media (max-width: 768px) {
	.pst-coach {
		font-size: 15px;
		bottom: 5px;
		left: 15px;
	}
}
.pst-coach span {
	display: block;
	transform: rotate(-90deg); /* Annulla la rotazione per il testo */
	transform-origin: left top; /* Assicurati di impostare un origine adeguata per il testo */
}
.x-pst {
	position: absolute;
	bottom: 0;
}
.scrollable-row {
	min-height: 500px;
	overflow-y: auto;
}
.fs-squad {
	font-size: 14px;
}
@media (min-width: 768px) {
	.fs-squad {
		font-size: 18px;
	}
}
.stast-pst {
	position: absolute;
	top: 0;
	right: 10px;
	z-index: 9999;
}
.cancel {
	position: relative;
	z-index: 9999;
}
.crown-pst {
	position: absolute;
	top: 20px;
	right: 10px;
}
.star-pst {
	position: absolute;
	top: 20px;
	left: 10px;
}
.clsx-scroll {
	overflow-y: auto;
	max-height: 400px;
}
.fanta-scroll {
	overflow-y: auto;
	max-height: 600px;
}
.sticky-top.my-top {
	position: sticky;
	top: -2px;
	z-index: 1020;
}
@media (max-width: 978px) {
	.margin-lg {
		margin-top: -40px;
	}
}
.statistiche-container {
	display: flex;
	overflow-x: auto;
	scroll-snap-type: x mandatory;
}

/* Stili per il singolo contenitore della tabella */
.table-wrapper {
	flex: 0 0 auto;
	scroll-snap-align: start;
	width: 100%;
}

/* Stili per la tabella */
.statistiche-table {
	width: 100%;
	margin-bottom: 20px;
}
.squad-hvr {
	transition: all 0.2s ease-in-out;
}
.squad-hvr:hover {
	filter: drop-shadow(0px 0px 5px rgba(255, 255, 255, 0.5));
	z-index: 2;
}
.squad-hvr:hover .player-number {
	color: #c0a96896;
	z-index: 1;
}
.squad-hvr:hover img {
	z-index: 3;
}
.squad-hvr:hover .banner {
	background-color: #121634;
}
.blurred-img {
	filter: blur(10px);
}
.squad-name {
	opacity: 0;
	transition: opacity 0.2s ease-in-out;
}
.event-group:hover .squad-name,
.event-group:active .squad-name {
	opacity: 1;
}
.space {
	height: 0;
}
@media (min-width: 768px) {
	.space {
		height: 50px;
	}
}
.exit-pst {
	position: absolute;
	top: -10px;
	right: -5px;
}
.exit-pst-modal {
	position: absolute;
	top: 0px;
	right: 15px;
	font-size: 13px;
}
.min-vw-90 {
	min-width: 90vw;
}
.min-vh-90 {
	min-height: 90vh;
}
.fanta-list {
	list-style-type: none;
	padding-left: 20px;
}
.fanta-list li {
	position: relative;
	padding-left: 30px;
	margin-bottom: 10px;
}
.fanta-list li::before {
	content: "";
	background-image: url("/public/assets/img/logo-noback.webp");
	background-size: contain;
	background-repeat: no-repeat;
	position: absolute;
	left: 0;
	top: 5px;
	width: 20px;
	height: 20px;
}
@media (min-width: 968px) {
	.fantaIcon {
		width: 50%;
		margin-inline: auto;
	}
}
.fanta-hvr {
	transition: all 0.3s ease-in-out;
}
.fanta-hvr:hover {
	filter: drop-shadow(0px 0px 5px rgba(255, 255, 255, 0.5));
}
.threedot {
	display: inline-block;
	transition: transform ease-in-out 0.3s;
}
.drop-menu-squad .dropdown-item {
	position: relative;
	transition: 0.5s;
	border-radius: 4px;
	background-color: var(--sgblu-color);
	font-size: 14px;
	font-weight: 400;
	padding: 5px 5px 5px 10px;
	color: #fff;
	margin: 2px 0;
	text-decoration: none;
}
.h-girone {
	color: transparent !important;
	background-image: linear-gradient(#ffdb7d, #07080a);
	background-size: cover;
	background-position: center;
	background-clip: text;
	-webkit-background-clip: text;
}
.major {
	margin: auto;
	width: 80%;
}
.negative-m {
	margin-inline: -10px;
}

.toggle9 {
	position: relative;
	display: inline-block;
}

.toggle9 label {
	width: 60px; /* Riduci la larghezza */
	height: 30px; /* Riduci l'altezza */
	background-color: #b9b8b8;
	position: relative;
	display: inline-block;
	border-radius: 50px;
	transition: 0.4s;
}

.toggle9 label:after {
	content: "";
	position: absolute;
	width: 26px; /* Riduci la larghezza */
	height: 26px; /* Riduci l'altezza */
	border-radius: 100%;
	left: 2px; /* Aggiorna il posizionamento per centrare */
	z-index: 2;
	background-color: #fff;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	transition: 0.4s;
	top: 2px;
}

.toggle9 input {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 5;
	opacity: 0;
	cursor: pointer;
}

.toggle9 input:hover + label:after {
	box-shadow:
		0 2px 15px 0 rgba(0, 0, 0, 0.2),
		0 3px 8px 0 rgba(0, 0, 0, 0.15);
}

.toggle9 input:checked + label:after {
	left: 32px; /* Aggiorna il posizionamento per centrare */
}

.toggle9:after {
	content: "✘";
	color: var(--sgblu-color);
	position: absolute; /* Cambia da relative a absolute */
	right: 10px; /* Aggiorna il posizionamento per centrare */
	bottom: 5px; /* Aggiorna il posizionamento per centrare */
	font-size: 18px; /* Riduci la dimensione del font */
}

.toggle9:before {
	content: "✔";
	position: absolute; /* Cambia da relative a absolute */
	left: 10px; /* Aggiorna il posizionamento per centrare */
	bottom: 5px; /* Aggiorna il posizionamento per centrare */
	color: #fff;
	z-index: 1;
	font-size: 18px; /* Riduci la dimensione del font */
}

.toggle9 input:checked + label {
	background: var(--gold-color);
}

.toggle9 input:checked + label:after {
	top: 2px;
	left: 32px; /* Aggiorna il posizionamento per centrare */
}
.glass-card {
	background: rgba(255, 255, 255, 0.02);
	backdrop-filter: blur(10px);
	-webkit-backdrop-filter: blur(10px);
	border-radius: 25px;
	box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
	background-color: rgba(255, 255, 255, 0.02);
}
.glass-card-50 {
	background: rgba(255, 255, 255, 0.02);
	backdrop-filter: blur(10px);
	-webkit-backdrop-filter: blur(10px);
	border-radius: 50%;
	box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
	padding: 15px;
	background-color: rgba(255, 255, 255, 0.02);
}

.glass-card-dark {
	background: rgba(0, 0, 0, 0.02);
	backdrop-filter: blur(10px);
	-webkit-backdrop-filter: blur(10px);
	border-radius: 25px;
	box-shadow: 0 4px 15px rgba(255, 255, 255, 0.2);
	background-color: rgba(255, 255, 255, 0.02);
}

.glass-card-drop {
	background: #20275b;
	backdrop-filter: blur(20px);
	-webkit-backdrop-filter: blur(20px);
	border-radius: 25px;
	box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}
.glass-card-news {
	background: rgba(255, 255, 255, 0.02);
	backdrop-filter: blur(30px);
	-webkit-backdrop-filter: blur(30px);
	border-radius: 25px;
	box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
	background-color: rgba(255, 255, 255, 0.02);
}

#countdown {
	margin: auto;
	height: 40px;
	width: 40px;
	text-align: center;
}

#countdown-number {
	color: white;
	display: inline-block;
	line-height: 40px;
}

svg.circle {
	position: absolute;
	right: 0;
	width: 40px;
	height: 40px;
	transform: rotateY(-180deg) rotateZ(-90deg);
}

svg.circle circle {
	stroke-dasharray: 113px;
	stroke-dashoffset: 0px;
	stroke-linecap: round;
	stroke-width: 2px;
	stroke: white;
	fill: none;
	animation: countdown 5s linear forwards;
}

@keyframes countdown {
	from {
		stroke-dashoffset: 0px;
	}
	to {
		stroke-dashoffset: 113px;
	}
}
/* Dropdown per desktop
.custom-dropdown-menu {
	top: 100%;
	left: 0;
	margin-top: var(--bs-dropdown-spacer);
}

/* Dropdown per mobile */
/* @media (max-width: 768px) {
	.mobile-dropdown {
		position: absolute !important;
		top: auto;
		bottom: 100%;
		left: 0;
		margin-bottom: var(--bs-dropdown-spacer);
		position: absolute;
	}
} */

/* .navbar-nav .dropdown-menu {
	position: absolute;
} */
/* .dropdown-menu[data-bs-popper] {
	top: 0;
	left: -25px;
	bottom: 110%;
	margin-top: var(--bs-dropdown-spacer);
}

@media (min-width: 768px) {
	.dropdown-menu[data-bs-popper] {
		top: 110%;
		left: -25px;
		bottom: 0;
		margin-top: var(--bs-dropdown-spacer);
	}
}  */
/* .custom-dropdown-menu[data-bs-popper] {
	top: 55px;
	bottom: 0 !important;
	left: -25px !important;
	margin-top: var(--bs-dropdown-spacer);
	min-height: 110px;
}

@media (max-width: 768px) {
	.custom-dropdown-menu[data-bs-popper] {
		top: -190px;
		position: absolute;
		bottom: 55px !important;
		left: -70px !important;
		margin-top: var(--bs-dropdown-spacer);
		min-height: 110px;
	}
	.custom-dropdown-menu-production[data-bs-popper] {
		top: -195px;
		position: absolute;
		bottom: 55px !important;
		left: -70px !important;
		margin-top: var(--bs-dropdown-spacer);
		min-height: 110px;
	}
} */

.custom-modal-content {
	position: fixed;
	top: -100px;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.6);
	z-index: 1050;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.custom-modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1040;
}
.dropdown-item {
	color: var(--gold-color);
	background-color: transparent;
}
.dropdown-item:focus,
.dropdown-item:hover,
.dropdown-item:active,
.dropdown-item {
	color: #fff;
	background-color: transparent;
}
.dropdown-item.active,
.dropdown-item:active {
	background-color: transparent;
}
.title-clsf {
	white-space: nowrap;
	display: block;
}
@media (max-width: 767.98px) {
	.logo-casa,
	.logo-ospite {
		width: 70px;
		height: auto;
	}
	.time {
		font-size: 1.5rem;
	}

	.result {
		font-size: 25px;
	}

	.nav-link {
		font-size: 1rem;
	}
}

@media (min-width: 768px) {
	.result-section {
		margin: 0 1rem;
	}

	.logo-casa,
	.logo-ospite {
		width: 150px;
		height: auto;
	}

	.title-clsf {
		font-size: 1.5rem;
	}

	.time {
		font-size: 2rem;
	}

	.result {
		font-size: 3rem;
	}

	.nav-link {
		font-size: 1.25rem;
	}
}

@media (min-width: 978px) {
	.result {
		font-size: 4.3rem;
	}
	.time {
		font-size: 2.5rem;
	}
}
.link-hvr {
	color: #fff;
}
.link-hvr:hover {
	color: var(--gold-color);
}
.giocatoreNumero,
.minuto {
	font-size: 12px;
}
.result-out {
	font-size: 19px;
}
.logo-small {
	width: 20px;
	height: 20px;
}

.cartellino {
	width: 16px;
	height: 16px;
}
.small-font {
	font-size: 1.35rem !important;
}
@media (min-width: 370px) {
	.result-out {
		font-size: 1.6rem;
	}
}
@media (min-width: 400px) {
	.result-out {
		font-size: 2.1rem;
	}
}
@media (min-width: 768px) {
	.result-out {
		font-size: 3.5rem;
	}
	.giocatoreNumero,
	.minuto {
		font-size: 20px;
	}
	.logo-small {
		width: 35px;
		height: 35px;
	}
	.goal-icon {
		height: 25px;
		width: 25px;
	}
}
@media (min-width: 992px) {
	.result-out {
		font-size: 4rem;
	}
}
.slick-prev:before,
.slick-next:before {
	color: var(--gold-color) !important;
	-webkit-backdrop-filter: blur(10px);
	backdrop-filter: blur(10px);
	box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}
.bg-glass {
	background-color: var(--sgblu-color);
}
.scroll-event {
	overflow-y: auto;
	max-height: 240px;
}
.event-item {
	display: flex;
	align-items: center;
	width: 100%;
	white-space: nowrap;
}

.giocatoreNome {
	flex-grow: 1;
	overflow: hidden;
	text-overflow: ellipsis;
}

.w-personal {
	width: 100%;
}
.scrollable-list {
	max-height: 510px;
	overflow-y: auto;
	margin-top: 5px;
}
@media (max-width: 768px) {
	.squadra-casa,
	.squadra-ospite {
		display: none;
	}

	.eventi-centrali {
		display: block;
	}
}
@media (min-width: 769px) {
	.eventi-centrali {
		display: none;
	}

	.squadra-casa,
	.squadra-ospite {
		display: block;
	}
}

.my-fs-out {
	font-size: 11px;
}
@media (min-width: 374px) {
	.my-fs-out {
		font-size: 15px;
	}
}
@media (min-width: 468px) {
	.giocatoreNome {
		flex-grow: 0;
	}
	.w-personal {
		width: 20%;
	}
	.my-fs-out {
		font-size: 20px;
	}
}
@media (min-width: 992px) {
	.event-item {
		justify-content: space-between;
	}
	.w-personal {
		width: 45%;
	}
	.my-fs-out {
		font-size: 25px;
	}
	.scrollable-list {
		max-height: 325px;
	}
}
.slider-hvr {
	position: relative;
	perspective: 1000px;
}

.slider-hvr .slick-slide {
	transition:
		transform 0.25s ease-in-out,
		opacity 0.1s ease-in-out;
}

.slider-hvr .slick-center .slide {
	transform: scale(1) translateZ(100px);
	opacity: 1; /* Opacità piena per l'immagine centrale */
	z-index: 2; /* Porta l'immagine centrale in primo piano */
}

.slider-hvr .slick-slide:not(.slick-center) .slide {
	transform: scale(0.8) translateZ(-100px) rotateY(15deg); /* Riduce la dimensione, trasla indietro e ruota */
	opacity: 0.6; /* Opacità ridotta per le immagini laterali */
	z-index: 1;
}

.slider-hvr .slick-prev,
.slider-hvr .slick-next {
	z-index: 3; /* Porta i pulsanti di navigazione in primo piano */
}

.slick-list {
	overflow: visible; /* Permette di vedere le slide parzialmente fuori */
}
.state-pst {
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	font-size: 20px;
	text-align: center;
}
.card-container {
	perspective: 1000px;
	width: 200px;
	height: 200px;
}

.card-content {
	position: relative;
	width: 100%;
	height: 100%;
	transform-style: preserve-3d;
	transition: transform 0.7s;
	cursor: pointer;
}

.card-container:hover .card-content {
	transform: rotateY(180deg);
}

.card-front,
.card-back {
	position: absolute;
	width: 100%;
	height: 100%;
	backface-visibility: hidden;
	border-radius: 50%;
	overflow: hidden;
}

.card-front {
	background-color: #fff;
}

.card-back {
	background-color: var(--sgblu-color);
	color: var(--gold-color);
	transform: rotateY(180deg);
}

.card-front .card-img-top {
	border-radius: 50%;
}

.pst-name-chisiamo,
.pst-number-chisiamo {
	position: absolute;
	width: 100%;
	text-align: center;
	color: white;
	text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

.pst-name-chisiamo {
	bottom: 40px;
	font-size: 1.2em;
}

.pst-number-chisiamo {
	bottom: 20px;
	font-size: 1.5em;
}
.circle-container {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
}

.circle {
	width: 20px;
	height: 20px;
	background-color: white;
	border-radius: 50%;
}
.pst-circle-chisiamo {
	position: absolute;
	bottom: 10px;
	z-index: 100;
}

@media (max-width: 992px) {
	.circle-container {
		flex-direction: row;
		justify-content: center;
	}
}
.tournament-bracket .logo {
	width: 50px;
	height: 50px;
}

.tournament-bracket .line {
	flex-grow: 1;
	height: 2px;
	background-color: black;
	margin: 0 10px;
}
.collapse-content {
	max-height: 500px;

	overflow: hidden;
	transition:
		max-height 0.5s ease-in-out,
		opacity 0.5s ease-in-out;
}

.collapse-content.expanded {
	max-height: 1000px; /* Puoi regolare questo valore in base alle tue necessità */
}
.z-index {
	z-index: 1000;
}
.card-scale-effect {
	transition: transform 0.2s ease-in-out;
}

.card-scale-effect:active {
	transform: scale(1.25);
}
/* blu 20275b   dorato c0a968 */

.drop-shadow {
	filter: drop-shadow(0px 0px 5px rgba(255, 255, 255, 0.5));
}
.winner-logo {
	transition: transform 0.3s ease-in-out;
}

.player-item {
	background-color: #ffffff;
	transition:
		background-color 0.3s,
		transform 0.3s;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.player-item {
	max-height: 450px; /* Regola questa altezza secondo le tue necessità */
}

.player-list {
	margin-top: 20px;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}
.my-fs-fanta-final {
	font-size: 3rem;
}
.logo-squad-create-final {
	height: 90px;
	width: 90px;
}
@media (min-width: 768px) {
	.logo-squad-create-final {
		height: 140px;
		width: 140px;
	}
}
.player-number-fanta-final {
	position: absolute;
	top: 35%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: -1;
	transition: all 0.3s ease-in-out;
	opacity: 0.7;
	font-size: 6rem;
}
@media (min-width: 768px) {
	.player-number-fanta-final {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: -1;
		transition: all 0.3s ease-in-out;
		opacity: 0.7;
		font-size: 6rem;
	}
}
.player-name-final {
	left: 50%;
	top: 50%; /* Modifica questa proprietà per posizionare verticalmente il testo */
	transform: translateX(-50%);
	position: absolute;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: block;
	color: var(--gold-color);
	transition: all 0.3s ease-in-out;
	font-size: 2rem;
}
@media (min-width: 768px) {
	.player-name-final {
		top: 60%;
		font-size: 1.6rem;
	}
}

.custom-badge {
	background-color: unset;
	color: var(--gold-color); /* Puoi personalizzare il colore del testo se necessario */
}
.custom-badge span {
	font-size: 0.9rem;
}

@media (min-width: 768px) {
	.player-goal,
	.player-number-fanta-final {
		position: absolute;
		top: 30%;
		left: 45%;
		transform: translate(-50%, -50%);
		z-index: -1;
		font-size: 12rem;
	}
}
.dropdown-menu {
	background-color: none !important;
}
.rounded-personal {
	border-radius: 25px;
}
